/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {

		/*
		*
		* AJAX FILTER
		*
		*/

		$('.filter-actu-link').on('click', function(e){
			e.preventDefault();

			$('.filter-actu-link').removeClass('active');
			$(this).addClass('active');

			var filterCategory = $(this).data('filter');

			$.post(
				ajaxurl,
				{
					'action': 'actu_action',
					'param': filterCategory
				},
				function(response){
					$('.actus-container').fadeOut(100, function(){
						$('.actus-container').html(response).fadeIn();
					});
				}
			);
		});

		/*
		*
		* AJAX SCROLL
		* ------------------
		*/

		const nbPosts = $('.actus-container').data('posts');

		$('.load-more-btn').on('click', function(e){

			e.preventDefault();

			let offset = $('.actu-item').length

			let datas = {
				'action': 'scroll_action',
				'param': offset
			}

			$.ajax({
				url: ajaxurl,
				type: 'POST',
				data: datas,
				beforeSend: () => {
					$('.ajax-img').fadeIn(400);
				},
				success: (response, status) => {
					if ((offset + 9) >= nbPosts) {
						$('.ajax-img').hide();
						$('.actus-container').append(response);
						$('.load-more-btn').hide();
					} else {
						$('.ajax-img').hide();
						$('.actus-container').append(response);
					}
				},
				error: (response, status, error) => {
					console.log(response, status, error);
				}
			})

		});

		/*
		* SWIPER ARTICLES SUGÉRÉ
		*/

		if ($('.related-posts').length > 0 && $('.last-actus-item').length > 3) {

			var swiperRelated = $('.swiper-container');

			new Swiper(swiperRelated, {
					loop: false,
					slidesPerView: 3,
					setWrapperSize: true,
					speed: 400,
					slideClass: 'last-actus-item',
					navigation: {
					nextEl: '.next-btn',
					prevEl: '.prev-btn',
				},
					breakpoints: {
						767: {
							slidesPerView: 1
						},
						990: {
							slidesPerView: 2
						}
					}
			});

		}


	}
}

