/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {

		var anchor = window.location.hash.split("#")[1];
		const tlAbout = new TimelineMax({ paused: true });

		if (anchor) {
			$("#" + anchor).addClass('current-item').show();
		} else {
			var defaultSection = $('.about-item:first-child').show().addClass('current-item');
		}

		var initialHeight = $('.filter-about-results').find('.current-item').height();
		$('.filter-about-results').css('height', initialHeight + 'px');

		$('.filter-about').on('click', function(e){

			e.preventDefault();
			var futurEl = $(this).attr('href');
			var futurEls = $(futurEl)
			var currentEl = $('.filter-about-results').find('.current-item');
			var currentElHeight = futurEls.height();

			function updateCurrent() {
				currentEl.removeClass('current-item');
				futurEls.addClass('current-item')
			}

			tlAbout.to('.filter-about-results', 0, {height: currentElHeight + 'px'}, '+=0.2')
				   .fromTo(currentEl, 0.5, {x:0, autoAlpha:1, display: "block"}, {x:-100, autoAlpha:0, display: "none"}, 0.1)
				   .fromTo(futurEls, 1, {x:100, autoAlpha:0, display: "none"}, {x:0, autoAlpha:1, display: "block", onComplete: updateCurrent}, '+=0.1')
		    tlAbout.play();

			$('.active').removeClass('active');
			$(this).addClass('active');

			history.pushState(null, null, $(this)[0].hash.toString());

		});


	}
}

