/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {

		/*
		*
		* CUSTOM SCROLL BAR
		*
		*/

		/*
		* ADJUST HEIGHT OF ELEMENTS
		*/
		var refHeight = $('.avocat-picture').height(),
		 		heightOfScrollEl = refHeight + 100,
		 		scrollableElement = $('.scrollable-element');

		if ($(window).width() > 767) {
			scrollableElement.css('height', heightOfScrollEl + "px");
			$('.scrollbar').css('height', refHeight + "px");
		} else {
			scrollableElement.css('height', "auto");
			$('.scrollbar').css('height', "auto");
		}

		/*
		* ON SCROLL
		*/
		scrollableElement.on('scroll', function(){

		  var s = scrollableElement.scrollTop(),
		      d = scrollableElement[0].scrollHeight,
		      c = scrollableElement.height();

		  var scrollPercent = (s / (d - c)) * 100;
			var tweenValue = "calc(" + scrollPercent + "% - 50px)";

			if ( ((s / (d - c))) *  c < 50 ) {
				tweenValue = scrollPercent;
			}

			TweenMax.to('.scroll-position', 0.5, { top: tweenValue });

		});

		/*
		* ON RESIZE
		*/

		$(window).on('resize', function(){

			// ADJUST HEIGHT OF ELEMENTS ON resize

			var refHeight = $('.avocat-picture').height(),
			 		heightOfScrollEl = refHeight + 100,
			 		scrollableElement = $('.scrollable-element');

			if ($(window).width() > 767) {
				scrollableElement.css('height', heightOfScrollEl + "px");
				$('.scrollbar').css('height', refHeight + "px");
			} else {
				scrollableElement.css('height', "auto");
				$('.scrollbar').css('height', "auto");
			}


		});

		/*
		* SWIPER ACTUS ASSOCIÉS
		*/

		if ($('.actu--associated').length > 0 && $('.avocat-actu-item').length > 3) {

			var swiperAssociated = $('.swiper-container');

			new Swiper(swiperAssociated, {
					loop: false,
					slidesPerView: 3,
					setWrapperSize: true,
					watchOverflow: true,
					speed: 400,
					slideClass: 'avocat-actu-item',
					navigation: {
					nextEl: '.next-btn',
					prevEl: '.prev-btn',
				},
					breakpoints: {
						767: {
							slidesPerView: 1
						},
						990: {
							slidesPerView: 2
						}
					}
			});

		}

	}
}

