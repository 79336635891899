/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs & dependancies
|--------------------------------
*/
import Swiper from 'swiper/dist/js/swiper.min';
require('../lib/iziModal/js/iziModal.js')($); //désolé

/*
|
| Importing Animations libs
|----------------------------
*/
 import { TweenMax } from "../lib/gsap/TweenMax";
 import TimelineMax from "../lib/gsap/TimelineMax";
 import SplitText from "../lib/gsap-pro/SplitText";
 import ScrollTo from "../lib/gsap/ScrollToPlugin";
 //import '../lib/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
 //import '../lib/scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
 //import '../lib/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
 //import ScrollMagic from '../lib/scrollmagic';
//import SubZero from '../lib/subzero/subzero.js';

/*
|
| Importing Utils
|----------------------------
*/
import Router from './utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from './components/global.js';
import main from './pages/main.js';
import avocats from './pages/avocats.js';
import singleAvocats from './pages/single-avocats.js';
import about from './pages/about.js';
import singleExpertise from './pages/single-expertise.js';
import actualites from './pages/actualites.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
	{
		'file': main,
		'dependencies': [app]
	},
	{
		'file': avocats,
		'dependencies': [app]
	},
	{
		'file': singleAvocats,
		'dependencies': [app, Swiper]
	},
	{
		'file': about,
		'dependencies': [app]
	},
	{
		'file': singleExpertise,
		'dependencies': [app, Swiper]
	},
	{
		'file': actualites,
		'dependencies': [app, Swiper]
	}
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

