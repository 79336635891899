/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {

		/*
		* SWIPER ÉQUIPES
		*/
		var swiperEL = $('.swiper-container');
		new Swiper(swiperEL, {
				loop: false,
				slidesPerView: 4,
				setWrapperSize: true,
				speed: 400,
				slideClass: 'avocat-item',
				navigation: {
		      nextEl: '.next-btn',
		      prevEl: '.prev-btn',
		    },
				breakpoints: {
					767: {
						slidesPerView: 1
					},
					990: {
						slidesPerView: 3
					}
				}
		});

		$(".accordion h2").on('click', function () {
			if ($(this).parent().hasClass("open")) {
				$(this).parent().removeClass('open');
			}
			else
				$(this).parent().addClass('open');
		});


	}
}

