/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {

		var letterA = $('.avocats-list').data('a');
		var letterR = $('.avocats-list').data('r');
		var letterH = $('.avocats-list').data('h');

		var bg = [
			letterA,
			letterR,
			letterH,
		]

		function getRandomInt(max) {
  		return Math.floor(Math.random() * Math.floor(max));
		}

		/*
		*
		* AJAX FILTER
		*
		*/

		$('.filter-link').on('click', function(e){
			e.preventDefault();

			$('.filter-link').removeClass('active');
			$(this).addClass('active');

			var filterCategory = $(this).data('filter');

			$.post(
				ajaxurl,
				{
					'action': 'filter_action',
					'param': filterCategory
				},
				function(response){
					$('.avocats-list').fadeOut(100, function(){
						$('.avocats-list').html(response).fadeIn();

						/*
						*
						* RANDOM HOVER
						*
						*/
						$('.mouseover-fix').on('mouseover', function(){

							var maskEl = $(this).closest('.avocat-item').find('.thumbnail-mask');
							$(maskEl).css('backgroundImage', 'url('+ bg[getRandomInt(3)] + ')');

							TweenMax.fromTo(maskEl, 0.5, { display: "none", autoAlpha:0 }, { display: "block", autoAlpha:1 });

						});

						$('.mouseover-fix').on('mouseleave', function(){

							var maskEl = $(this).closest('.avocat-item').find('.thumbnail-mask');
							TweenMax.fromTo(maskEl, 0.5, { display: "block", autoAlpha:1 }, { display: "none", autoAlpha:0 });

						});


					});
				}
			);

		});

		/*
		*
		* RANDOM HOVER
		*
		*/

		$('.mouseover-fix').on('mouseover', function(){

			var maskEl = $(this).closest('.avocat-item').find('.thumbnail-mask');
			$(maskEl).css('backgroundImage', 'url('+ bg[getRandomInt(3)] + ')');

			TweenMax.fromTo(maskEl, 0.5, { display: "none", autoAlpha:0 }, { display: "block", autoAlpha:1 });

		});

		$('.mouseover-fix').on('mouseleave', function(){

			var maskEl = $(this).closest('.avocat-item').find('.thumbnail-mask');
			TweenMax.fromTo(maskEl, 0.5, { display: "block", autoAlpha:1 }, { display: "none", autoAlpha:0 });

		});


	}
}

