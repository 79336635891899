/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {

		/*
		*
		* LOADER
		*
		*/

		$(window).on('load', function() {
			$('body').removeClass('body-load');
		});

		/*
		*
		* MOBILE NAVIGATION
		*
		*/

		var menuTl = new TimelineMax({paused:true});
		    menuTl.fromTo('.navigation-mobile', 0.5, {display:"none", x: "-100%", ease: Power3.easeInOut}, {display:'block', x: "0%", ease: Power3.easeInOut}, 0);

		$('.toggle-nav-mobile').on('click', function(e){
			e.preventDefault();
			menuTl.timeScale(2).play();
		});

		$('.close-nav').on('click', function(e){
			e.preventDefault();
			menuTl.timeScale(4).reverse();
		});

		/*
		*
		* SCROLL TO ANCHOR
		*
		*/

		function scrollTo( target ) {
        if( target.length ) {
            $("html, body").animate( { scrollTop: target.offset().top }, "slow");
        }
    }

		$('.menu-item-282, .menu-item-336').on('click', function(){
			var anchorLink = $(this).find('a').attr('href');
			menuTl.timeScale(4).reverse();
			scrollTo( $(anchorLink) );
		});

		/*
		*
		* CUSTOM GOOGLE MAP IN FOOTER
		*
		*/

		function initMap() {

		  var herald = {lat: 48.872026, lng: 2.313092};

		  var map = new google.maps.Map(
		      document.getElementById('map'), {
						zoom: 18,
						center: herald,
						disableDefaultUI: true,
						styles: [
					  {
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#393939"
					      }
					    ]
					  },
					  {
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#746855"
					      }
					    ]
					  },
					  {
					    "elementType": "labels.text.stroke",
					    "stylers": [
					      {
					        "color": "#2b2b2b"
					      }
					    ]
					  },
					  {
					    "featureType": "administrative",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "visibility": "off"
					      }
					    ]
					  },
					  {
					    "featureType": "administrative.locality",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#d59563"
					      }
					    ]
					  },
					  {
					    "featureType": "poi",
					    "stylers": [
					      {
					        "visibility": "off"
					      }
					    ]
					  },
					  {
					    "featureType": "poi",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#d59563"
					      }
					    ]
					  },
					  {
					    "featureType": "poi.park",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#263c3f"
					      }
					    ]
					  },
					  {
					    "featureType": "poi.park",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#6b9a76"
					      }
					    ]
					  },
					  {
					    "featureType": "road",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2f2f2f"
					      }
					    ]
					  },
					  {
					    "featureType": "road",
					    "elementType": "geometry.stroke",
					    "stylers": [
					      {
					        "color": "#212a37"
					      }
					    ]
					  },
					  {
					    "featureType": "road",
					    "elementType": "labels.icon",
					    "stylers": [
					      {
					        "visibility": "off"
					      }
					    ]
					  },
					  {
					    "featureType": "road",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#636363"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#746855"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "geometry.stroke",
					    "stylers": [
					      {
					        "color": "#1f2835"
					      }
					    ]
					  },
					  {
					    "featureType": "road.highway",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#f3d19c"
					      }
					    ]
					  },
					  {
					    "featureType": "transit",
					    "stylers": [
					      {
					        "visibility": "off"
					      }
					    ]
					  },
					  {
					    "featureType": "transit",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#2f3948"
					      }
					    ]
					  },
					  {
					    "featureType": "transit.station",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#d59563"
					      }
					    ]
					  },
					  {
					    "featureType": "water",
					    "elementType": "geometry",
					    "stylers": [
					      {
					        "color": "#17263c"
					      }
					    ]
					  },
					  {
					    "featureType": "water",
					    "elementType": "labels.text.fill",
					    "stylers": [
					      {
					        "color": "#515c6d"
					      }
					    ]
					  },
					  {
					    "featureType": "water",
					    "elementType": "labels.text.stroke",
					    "stylers": [
					      {
					        "color": "#17263c"
					      }
					    ]
					  }
					]
					});

			var dataMarker = $('#map').data('marker');
		  var marker = new google.maps.Marker({position: herald, map: map, icon: dataMarker});

		}

		initMap();

	}
}

